<template>
  <div>
    <!-- Role Info: Input Fields -->
    <b-form>
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-alert
            v-model="showMessage"
            dismissible
            :variant="messageType"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="InfoIcon"
              />
              <span
                class="ml-25"
                v-html="message"
              />
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <!-- Field: Name -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="اسم الدور"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="roleData.name"
              disabled
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="اسم الدور بالعربي"
            label-for="name_arabic"
          >
            <b-form-input
              id="name_arabic"
              v-model="roleData.name_arabic"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- PERMISSION TABLE -->
    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">الأذونات</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="items"
        :fields="fields"
      >
        <template #cell(module)="data">
          {{ $t(data.value) }}
        </template>
        <template #cell()="data">
          <b-form-checkbox
            :checked="data.value"
            @change="setPermission($event, data)"
          />
        </template>
      </b-table>
    </b-card>

    <!-- EXTENDED PERMISSION TABLE -->
    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">الأذونات الموسّعة</span>
        </b-card-title>
      </b-card-header>
      <div
        v-for="(_fields, moduleName) in extendedPermissionsOptions"
        :key="moduleName"
      >
        <b-table
          striped
          responsive
          class="mb-0"
          :items="getPermissionsByModule(moduleName)"
          :fields="getFields(_fields)"
        >
          <template #cell(module)="data">
            {{ $t(data.value) }}
          </template>
          <template #cell()="data">
            <b-form-checkbox
              :disabled="isBuiltinRolePermission(moduleName,data)"
              :checked="data.value"
              @change="setPermission($event, data)"
            />
          </template>
        </b-table>
        <br>
      </div>
    </b-card>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateRole"
    >
      حفظ التغييرات
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      إعادة ضبط
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAlert,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from 'bootstrap-vue';
import { alertMessageMixin } from '@core/mixins/ui/feedback';

import { avatarText } from '@core/utils/filter';
import vSelect from 'vue-select';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { ref } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, alphaNum, email } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import store from '@/store';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useRolesList from '../roles-list/useRolesList';

export default {
  components: {
    BButton,
    BAlert,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [alertMessageMixin],
  props: {
    roleData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isBuiltinRolePermission(moduleName, permissionName) {
      return this.modulesPermissionsOptions[moduleName]
      && this.modulesPermissionsOptions[moduleName][this.roleData.name]
      && this.modulesPermissionsOptions[moduleName][this.roleData.name].includes(permissionName.field.key);
    },
    getFields(fields) {
      return [{
        key: 'module',
        label: 'الوحدة',
      }, ...fields.map((field) => ({
        key: field,
        label: this.$t(field),
      }))];
    },
    getPermissionsByModule(moduleName) {
      const currentPermissions = this.items.filter((item) => item.module == moduleName);
      return currentPermissions;
    },
    setPermission(event, data) {
      console.log(this.roleData.abilities);
      if (typeof this.roleData.abilities[data.item.module] !== 'undefined') {
        this.roleData.abilities[data.item.module][data.field.key] = event;
      } else {
        this.roleData.abilities[data.item.module] = {};
        this.roleData.abilities[data.item.module][data.field.key] = event;
      }
    },
    updateRole() {
      const allPermissions = [
        ...this.permissionOptions,
        ...Object.keys(this.extendedPermissionsOptions).map(module => this.extendedPermissionsOptions[module]).flat(),
      ];
      console.log(allPermissions);
      this.roleData.permissions = Object.keys(this.roleData.abilities)
        .map((key) => allPermissions
          .filter((value) => this.roleData.abilities[key][value] === true)
          .map((value) => `${key}_${value}`))
        .flat();
      this.$store
        .dispatch('app-role/updateRole', { roleData: this.roleData, id: this.roleData.id })
        .then((response) => {
          this.$toast.success(response.data.message);
          router.push('/role').catch(() => {});
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
  },
  setup(props) {
    const { resolveRoleRoleVariant } = useRolesList();
    const permissionOptions = ref([]);
    const moduleOptions = ref([]);
    const extendedPermissionsOptions = ref([]);
    const modulesPermissionsOptions = ref([]);
    // @todo: should we really have a seperate abilities attributes. There is already permissions
    props.roleData.abilities = props.roleData.permissions;
    const items = ref(Object.values(props.roleData.permissions));
    store
      .dispatch('app-role/fetchRoleOptions', {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        const {
          permissions, modules, extendedPermissions, modulesPermissions,
        } = response.data;
        permissionOptions.value = permissions;
        moduleOptions.value = modules;
        extendedPermissionsOptions.value = extendedPermissions;
        modulesPermissionsOptions.value = modulesPermissions;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          permissionOptions.value = undefined;
        }
      });

    const fields = [
      {
        key: 'module',
        label: 'الوحدة',
      },
      {
        key: 'read',
        label: 'قراءة',
      },
      {
        key: 'update',
        label: 'كتابة',
      },
      {
        key: 'create',
        label: 'إنشاء',
      },
      {
        key: 'delete',
        label: 'حذف',
      },
    ];

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    // const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
    //   // eslint-disable-next-line no-param-reassign
    //   props.roleData.avatar = base64
    // })
    const oldRoleData = JSON.parse(JSON.stringify(props.roleData));
    const resetroleData = () => {
      roleData.value = JSON.parse(JSON.stringify(oldRoleData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetroleData);

    return {
      resolveRoleRoleVariant,
      avatarText,
      fields,
      permissionOptions,
      moduleOptions,
      extendedPermissionsOptions,
      modulesPermissionsOptions,
      items,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
