<template>
  <component :is="roleData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="roleData === undefined"
    >
      <h4 class="alert-heading">
        خطأ في جلب بيانات الدّور
      </h4>
      <div class="alert-body">
        لم يتم العثور على مستخدم مع معرف الدّور هذا.راجع
        <b-link
          class="alert-link"
          :to="{ name: 'apps-roles-list'}"
        >
          قائمة الدّور
        </b-link>
        لأدور اخرى.
      </div>
    </b-alert>

    <b-tabs
      v-if="roleData"
      pills
    >
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="RoleIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('role_edit') }}</span>
        </template>
        <roles-edit-tab-account
          :role-data="roleData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import roleStoreModule from '../roleStoreModule';
import RolesEditTabAccount from './RolesEditTabAccount.vue';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    RolesEditTabAccount,
  },
  setup() {
    const roleData = ref(null);

    const ROLE_APP_STORE_MODULE_NAME = 'app-role';

    // Register module
    if (!store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.registerModule(ROLE_APP_STORE_MODULE_NAME, roleStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.unregisterModule(ROLE_APP_STORE_MODULE_NAME);
    });

    store.dispatch('app-role/fetchRole', { id: router.currentRoute.params.id })
      .then((response) => {
        const { data: role } = response.data;
        roleData.value = role;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          roleData.value = undefined;
        }
      });

    return {
      roleData,
    };
  },
};
</script>

<style>
input[type=checkbox][disabled]{
	cursor:not-allowed;
}
</style>
