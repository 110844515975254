export const useInputImageRenderer = (inputEl, previewEl) => {
  console.log('inputEl', inputEl);
  console.log('previewEl', previewEl);
  const inputImageRenderer = () => {
    const file = inputEl.value.files[0];

    if (file) {
      const reader = new FileReader();

      // Set up the FileReader onload event
      reader.onload = () => {
        // Update the previewEl with the chosen file
        previewEl.value.src = reader.result;
      };

      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };
  return {
    inputImageRenderer,
  };
};

export const _ = null;
