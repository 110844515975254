import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/role', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRole(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/role/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUsersRole(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/role/${id}/users`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateRole(ctx, { roleData, id }) {
      console.log(roleData, id);
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/role/${id}`, roleData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateUsersRole(ctx, { roleData: roles, id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/role/${id}/users`, { roles })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addRole(ctx, roleData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/role', roleData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRoleOptions(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/role/options')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteRole(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/role/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
