export const alertMessageMixin = {
  data() {
    return {
      showMessage: false,
      messageType: 'success',
      message: '',
    };
  },
  mounted() {
    this.$root.$on('showMessage', (message) => {
      this.displayMessage(message);
    });
  },
  beforeDestroy() {
    this.$root.$off([
      'showMessage',
    ]);
  },
  methods: {
    resetMessage() {
      this.showMessage = false;
      this.messageType = 'success';
      this.message = '';
    },
    displayMessage(message) {
      this.showMessage = true;
      this.message = message.message;
      this.messageType = message.type;
    },
  },
};

export const toastMessageMixin = {
};
